import { Component, OnInit } from '@angular/core';
import { faTags } from '@fortawesome/free-solid-svg-icons';
import { MessageService } from 'primeng/api';
import { CommonService } from 'src/app/shared/common.service';
import { TagsService } from '../tags.service';

@Component({
  selector: 'app-tag-sleep',
  templateUrl: './tag-sleep.component.html',
  styleUrls: ['./tag-sleep.component.css']
})
export class TagSleepComponent implements OnInit {

 
   //Icono de etiqueta
   tagsIcon = faTags;

   // Variables del formulario
   hasDiscount: boolean = false;
   discountedPrice: number | null = null;
   productName: string = '';
   productId: string = '';
   productPrice: number | null = null;
   quantityOption: string = '';
 
   // Variables para manejar la edición
   isEditing: boolean = false;
   editingIndex: number | null = null;
   editingList: Array<any> | null = null;
 
   // Arrays para almacenar los productos según la opción seleccionada
   up4List: Array<any> = [];
   up16List: Array<any> = [];
   up80List: Array<any> = [];
 
   constructor(
     private tagsService : TagsService,
     public commonService: CommonService,
     private messageService: MessageService
   ) { }
 
   ngOnInit(): void { }
 
   onSizeChange() {
     const option = !this.commonService.fullSize$.value;
     this.commonService.fullSize$.next(option);
   }
 
   submitForm() {
     // Validación básica
     if (!this.productName || !this.productId || this.productPrice === null) {
       this.messageService.add({severity:'error', summary:'Error', detail:'Por favor completa todos los campos requeridos.'});
       return;
     }
 
     // Editar un producto existente
     if (this.isEditing && this.editingList !== null && this.editingIndex !== null) {
       const product = this.editingList[this.editingIndex];
       product.productName = this.productName;
       product.productId = this.productId;
       product.productPrice = this.productPrice;
       product.hasDiscount = this.hasDiscount;
       product.discountedPrice = this.hasDiscount ? this.discountedPrice : null;
 
       // Resetear el formulario de edición
       this.isEditing = false;
       this.editingIndex = null;
       this.editingList = null;
     } else {
       // Agregar un nuevo producto a la lista correspondiente
       const newProduct = {
         productName: this.productName,
         productId: this.productId,
         productPrice: this.productPrice,
         hasDiscount: this.hasDiscount,
         discountedPrice: this.hasDiscount ? this.discountedPrice : null
       };
 
       if (this.quantityOption === 'Up 4') {
         this.up4List.push(newProduct);
       } else if (this.quantityOption === 'Up 16') {
         this.up16List.push(newProduct);
       } else if (this.quantityOption === 'Up 80') {
         this.up80List.push(newProduct);
       }
 
       // Resetear el formulario
       this.productName = '';
       this.productId = '';
       this.productPrice = null;
       this.hasDiscount = false;
       this.discountedPrice = null;
       this.quantityOption = '';
     }
   }
 
   // Función para manejar la edición de un producto
   editProduct(list: Array<any>, index: number) {
     const product = list[index];
     this.productName = product.productName;
     this.productId = product.productId;
     this.productPrice = product.productPrice;
     this.hasDiscount = product.hasDiscount;
     this.discountedPrice = product.discountedPrice;
 
     this.isEditing = true;
     this.editingIndex = index;
     this.editingList = list;
   }
 
   // Función para cancelar la edición
   cancelEdit() {
     this.isEditing = false;
     this.editingIndex = null;
     this.editingList = null;
   }
 
   // Función para eliminar un producto
   deleteProduct(list: Array<any>, index: number) {
     list.splice(index, 1);
   }
 
   LabelsPrint(): void {
     const ModuleName = 'sleep'
     this.tagsService.generateLabelsPDF(this.up4List, this.up16List, this.up80List, ModuleName);
   }
}
