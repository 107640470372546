import { CommonService } from 'src/app/shared/common.service';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import jsPDF from "jspdf";
import { environment } from "src/environments/environment";
import {catchError} from 'rxjs/operators'
import { Observable, throwError } from 'rxjs';
import { Product } from './tags.enums';

@Injectable({
  providedIn: "root",
})
export class TagsService {
  constructor(
    private http: HttpClient,
    private commonService:CommonService,
  ) {}

  handleRequestError(error: HttpErrorResponse){
    let errorMessage = ''

    if(error.error instanceof ErrorEvent){
      errorMessage = `Error: ${error.error.message}`
    }else{
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`
    }
    console.error(errorMessage);

    return throwError(()=> new Error(errorMessage))
  }

  public generateLabelsPDF(
    up4List: Array<any>,
    up16List: Array<any>,
    up80List: Array<any>,
    ModuleName: string
  ) {
      /*{ productName: "Producto 1",
          productId: "0001",
          productPrice: 1000.99, 
          hasDiscount: false, 
          discountedPrice: 0, 
          hasMeasurements: true, 
          Measurements: `132" x 94 3/4"` 
        }*/
    
    if (up4List && up4List.length > 0) {
      this.Up4Label(up4List, ModuleName);
    }

    if (up16List && up16List.length > 0) {
      this.Up16Label(up16List, ModuleName);
    }

    if (up80List && up80List.length > 0) {
      this.Up80Label(up80List, ModuleName);
    }
  }

  private async Up4Label(up4List: Array<any>, ModuleName: string) {
    const doc = new jsPDF({
      orientation: "p",
      unit: "cm",
      format: "letter",
    });

    const SpectralFont = "../../../assets/Font/Spectral-Bold-bold.js";
    doc.addFileToVFS("Spectral-Bold.js", SpectralFont);
    doc.addFont("Spectral-Bold.js", "Spectral", "Bold");

    let ImgURL = "";
    switch (ModuleName) {
      case "Ashley":
        ImgURL = "../../../assets/images/Ashley_etiquetas.png";
        break;
      case "hds":
        ImgURL = "../../../assets/images/hds.png";
        break;
      default:
        break;
    }

    const labelWidth = 10.85;
    const labelHeight = 13.95;

    const Postions = [
      { x: 0, y: 0, imgX: 0, imgY: 0 },
      { x: labelWidth, y: 0, imgX: labelWidth, imgY: 0 },
      { x: 0, y: labelHeight, imgX: 0, imgY: labelHeight },
      { x: labelWidth, y: labelHeight, imgX: labelWidth, imgY: labelHeight },
    ];

    let labelIndex = 0;

    const loadImage = (url: string) => {
      return new Promise<HTMLImageElement>((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = () => resolve(img);
        img.onerror = (err) => reject(err);
      });
    };

    const addLabel = async (
      item: any,
      x: number,
      y: number,
      imgX: number,
      imgY: number
    ) => {
      try {
        const img = await loadImage(ImgURL);

        const ImgWidth = 3.3;
        const ImgHeight = 2.4;

        const imgPaddingX = 3.8;
        const imgPaddingY = 1;
        const imgStartX = imgX + imgPaddingX;
        const imgStartY = imgY + imgPaddingY;

        const centerX = x + labelWidth / 2;
        const startY = y + 4;
        const lineHeight = 1;

        doc.addImage(img, "PNG", imgStartX, imgStartY, ImgWidth, ImgHeight);

        doc.setFontSize(20);
        doc.setFont("Helvetica", "normal");
        let textWidth = doc.getTextWidth(item.productName);
        doc.text(item.productName, centerX - textWidth / 2, startY);

        textWidth = doc.getTextWidth(item.productId);
        doc.text(item.productId, centerX - textWidth / 2, startY + lineHeight);

        doc.setFontSize(64);
        doc.setFont("Spectral", "bold");
        // Formatear el precio con comas para miles
        const formattedPrice = item.productPrice.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        });
        // Obtener el ancho del texto del precio formateado
        textWidth = doc.getTextWidth(`$${formattedPrice}`);
        // Centrar y renderizar el texto en el PDF
        doc.text(
          `$${formattedPrice}`,
          centerX - textWidth / 2,
          startY + 4 * lineHeight
        );

        doc.setFont("Helvetica", "normal");
        doc.setFontSize(11);
        textWidth = doc.getTextWidth("USD");
        doc.text("USD", centerX - textWidth / 2, startY + 4.6 * lineHeight);

        doc.setFontSize(13);
        textWidth = doc.getTextWidth("(+IVA)");
        doc.text("(+IVA)", centerX - textWidth / 2, startY + 5.2 * lineHeight);

        if (item.hasDiscount) {
          textWidth = doc.getTextWidth(
            `$${item.discountedPrice ? `${item.discountedPrice}` : "N/A"}`
          );
          doc.text(
            `$${item.discountedPrice ? `${item.discountedPrice}` : "N/A"}`,
            centerX - textWidth / 2,
            startY + 4 * labelHeight
          );
        }
      } catch (err) {
        this.commonService.showErrorMessage("Error al generar etiquetas Up4")
      }
    };

    for (const item of up4List) {
      const Positon = Postions[labelIndex % 4];
      await addLabel(item, Positon.x, Positon.y, Positon.imgX, Positon.imgY);
      labelIndex++;
      if (labelIndex % 4 === 0 && labelIndex < up4List.length) {
        doc.addPage();
      }
    }
    this.commonService.showSuccessMessage("Etiquetas Up4 generadas")
    doc.save("Etiquetas Up4.pdf");
  }

  private async Up16Label(up16List: Array<any>, ModuleName: string) {
    const doc = new jsPDF({
      orientation: "p",
      unit: "cm",
      format: "letter",
    });

    //Cargamos la fuente Spectral.tff
    const SpectralFont = "../../../assets/Font/Spectral-Bold.ttf"; 

    //definimos Spectral como fuente en jsPDF
    doc.addFileToVFS("Spectral-Bold.ttf", SpectralFont);
    doc.addFont("Spectral-Bold.ttf", "Spectral", "Bold");

    let ImgURL = "";
    switch (ModuleName) {
      case "Ashley":
        ImgURL = "../../../assets/images/Ashley_etiquetas.png";
        break;
      case "hds":
        ImgURL = "../../../assets/images/hds.png";
        break;
      default:
        break;
    }
    const labelWidth = 5.4;
    const labelHeight = 6.9;
    
    const Positions = [];
    
    for (let row = 0; row < 4; row++) {
      for (let col = 0; col < 4; col++) {
        Positions.push({
          x: col * labelWidth,
          y: row * labelHeight,
          imgX: col * labelWidth,
          imgY: row * labelHeight,
        });
      }
    }
    
    let labelIndex = 0;

    const loadImage = (url: string) => {
      return new Promise<HTMLImageElement>((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = () => resolve(img);
        img.onerror = (err) => reject(err);
      });
    };

    const addLabel = async (
      item: any,
      x: number,
      y: number,
      imgX: number,
      imgY: number
    ) => {
      try {
        const img = await loadImage(ImgURL);

        const ImgWidth = 2.4;
        const ImgHeight = 1.7;

        const imgPaddingX = 1.5;
        const imgPaddingY = 0.1;
        const imgStartX = imgX + imgPaddingX;
        const imgStartY = imgY + imgPaddingY;

        const centerX = x + labelWidth / 2;
        const startY = y + 2.5;
        const lineHeight = 0.75;

        doc.addImage(img, "PNG", imgStartX, imgStartY, ImgWidth, ImgHeight);

        doc.setFontSize(15);
        doc.setFont("Helvetica", "normal");
        let textWidth = doc.getTextWidth(item.productName);
        doc.text(item.productName, centerX - textWidth / 2, startY);
        textWidth = doc.getTextWidth(`$${item.Measurements ? `${item.Measurements}` : ''}`);
        doc.text(
          `${item.Measurements ? `${item.Measurements}` : ''}`,
          centerX - textWidth / 2,
          startY + lineHeight
        );
        textWidth = doc.getTextWidth(item.productId);
        doc.text(
          item.productId,
          centerX - textWidth / 2,
          startY + 0.8 + lineHeight
        );
        doc.setFontSize(32);
        doc.setFont("Spectral", "bold");
        const formattedPrice = item.productPrice.toLocaleString("en-US", {
          minimunFractionDigits: 2,
        });

        textWidth = doc.getTextWidth(`$${formattedPrice}`);
        doc.text(
          `$${formattedPrice}`,
          centerX - textWidth / 2,
          startY + 1.8 + lineHeight
        );

        doc.setFont("Helvetica", "normal");
        doc.setFontSize(10);
        textWidth = doc.getTextWidth("USD");
        doc.text("USD", centerX - textWidth / 2, startY + 2.3 + lineHeight);

        doc.setFontSize(12);
        textWidth = doc.getTextWidth("(+IVA)");
        doc.text("(+IVA)", centerX - textWidth / 2, startY + 2.8 + lineHeight);

        if (item.hasDiscount) {
          textWidth = doc.getTextWidth(
            `$${item.discountedPrice ? `${item.discountePrice}` : "N/A"}`
          );
          doc.text(
            `$${item.discountedPrice ? `${item.discountedPrice}` : "N/A"}`,
            centerX - textWidth / 2,
            startY + 4 * labelHeight
          );
        }
      } catch (err) {
        this.commonService.showErrorMessage("Error al generar etiquetas Up 16")
      }
    };

    for (const item of up16List) {
      const Position = Positions[labelIndex % 16];
      await addLabel(
        item,
        Position.x,
        Position.y,
        Position.imgX,
        Position.imgY
      );
      labelIndex++;
      if (labelIndex % 16 === 0 && labelIndex < up16List.length) {
        doc.addPage();
      }
    }
    this.commonService.showSuccessMessage("Etiquetas Up 16 Generadas")
    doc.save("Etiquetas Up16.pdf");
  }

  private async Up80Label(up80List: Array<any>, ModuleName: string) {
      const doc = new jsPDF({
        orientation: "p",
        unit: "cm",
        format: "letter",
      });

      // Carga y define la fuente Spectral-Bold
      const SpectralFont = "../../../assets/Font/Spectral-Bold.ttf";
      doc.addFileToVFS("Spectral-Bold.ttf", SpectralFont);
      doc.addFont("Spectral-Bold.ttf", "Spectral", "Bold");

      // Selección de la imagen según el módulo
      const imgURLs: Record<string, string> = {
        Ashley: "../../../assets/images/Ashley_etiquetas.png",
        hds: "../../../assets/images/hds.png",
      };
      const ImgURL = imgURLs[ModuleName] || "";

      // Definición de las posiciones de las etiquetas
      const labelWidth = 1.268181818181818;
      const labelHeight = 4.4;
      const margin = .75
      const Positions = [];

      const totalRows = 20; // Número total de filas (ajusta según sea necesario)
      const labelsPerRow = 4; // Cantidad de etiquetas por fila
      
      for (let row = 1; row <= totalRows; row++) {
          for (let col = 0; col < labelsPerRow; col++) {
              const x = col * labelHeight + margin * (col + 1);
              const y = labelWidth * row;
              Positions.push({
                  x: x,
                  y: row < 21 ? y : 1.268181818181818, 
                  imgX: x,
                  imgY: row < 21 ? y : 1.268181818181818 
              });
          }
      }
      

      let labelIndex = 0;

      const loadImage = (url: string) => {
        return new Promise<HTMLImageElement>((resolve, reject) => {
          const img = new Image();
          img.src = url;
          img.onload = () => resolve(img);
          img.onerror = (err) => reject(err);
        });
      };

      const addLabel = async (
        item: any,
        x: number,
        y: number,
        imgX: number,
        imgY: number
      ) => {
        try {
          const img = await loadImage(ImgURL);
          const ImgWidth = 1.3;
          const ImgHeight = 0.87;

          const imgPaddingX = 1.5;
          const imgPaddingY = 0;
          const imgStartX = imgX + imgPaddingX;
          const imgStartY = imgY + imgPaddingY;

          const centerX = x + labelWidth * 1.7;
          const startY = y + 0.85;
          const lineHeight = 0.3

          doc.addImage(img, "PNG", imgStartX, imgStartY, ImgWidth, ImgHeight);

          // Añadir nombre del producto y ID
          doc.setFontSize(5);
          doc.setFont("Helvetica", "bold");
          const productText = `${item.productName} ${item.productId}`;
          let textWidth = doc.getTextWidth(productText);
          doc.text(productText, centerX - textWidth / 2, startY);

          // Añadir precio con textos en diferentes tamaños
          const priceText = `$${item.productPrice.toLocaleString("en-US", {
            minimumFractionDigits: 2,
          })}`;
          const currencyText = "(USD)";
          const taxText = "(+IVA)";

          // Configura el tamaño de la fuente para el precio principal
          doc.setFontSize(8);
          doc.setFont("Spectral", "bold")
          let priceWidth = doc.getTextWidth(priceText + currencyText + taxText);
          doc.text(priceText, centerX - priceWidth / 2, startY + 0.3);

          // Ajusta el tamaño de la fuente para "(USD)" y "(+IVA)"
          doc.setFontSize(6);
          doc.setFont("Helvetica", "normal")
          doc.text(
            currencyText + taxText, centerX, startY + lineHeight
          )
        } catch (err) {
          this.commonService.showErrorMessage('Error al generar las etiquetas Up 80')
        }
      };

      for (const item of up80List) {
        const Position = Positions[labelIndex % 80];
        await addLabel(
          item,
          Position.x,
          Position.y,
          Position.imgX,
          Position.imgY
        );
        labelIndex++;
        if (labelIndex % 80 === 0 && labelIndex < up80List.length) {
          doc.addPage();
        }
      }
      this.commonService.showSuccessMessage("Etiquetas Up80 Generadas")
      doc.save("Etiquetas Up80.pdf");
  }

  SearchID(productId: string, ModuleName: string, quantityOption: string):Observable<Product[]>{
    const id_sb = productId
    const store = ModuleName
    return this.http.get<Product[]>(
      `${environment.endpoint}tags/search/${id_sb}/${store}`
    ).pipe(
      catchError(this.commonService.handleRequestError
      ))
  } 
}
