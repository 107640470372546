import { Product } from './../tags.enums';
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { faSpinner, faTags } from "@fortawesome/free-solid-svg-icons";
import { MessageService } from "primeng/api";
import { CommonService } from "src/app/shared/common.service";
import { TagsService } from "../tags.service";
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: "app-tags-hds",
  templateUrl: "./tag-hds.component.html",
  styleUrls: ["./tag-hds.component.css"],
  providers: [MessageService],
})
export class TagHdsComponent implements OnInit {
 
  products: Product[] = []
  tagsIcon = faTags;
  loadingIcon = faSpinner;
  loading = false;

  // Variables del formulario
  hasMeasurements: boolean = false;
  Measurements: string = "";
  //hasDiscount: boolean = false;
  //discountedPrice: number | null = null;
  productName: string = "";
  productId: string = "";
  productPrice: number | null = null;
  quantityOption: string = "";

  isEditing: boolean = false;
  editingIndex: number | null = null;
  editingList: Array<any> | null = null;

  up4List: Array<any> = [];
  up16List: Array<any> = [];
  up80List: Array<any> = [];

  constructor(
    private tagsService: TagsService,
    public commonService: CommonService,
    private cd : ChangeDetectorRef,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {}

  onSizeChange() {
    const option = !this.commonService.fullSize$.value;
    this.commonService.fullSize$.next(option);
  }

  submitForm() {
    // Validación
    if (!this.productName || !this.productId || this.productPrice === null) {
      this.commonService.showWarningMessage("Por favor complete los campos");
      return;
    }
  
    const newProduct: Product = {
      productName: this.productName,
      productId: this.productId,
      productPrice: this.productPrice,
      //hasDiscount: this.hasDiscount,
      //discountedPrice: this.hasDiscount ? this.discountedPrice : null,
      hasMeasurements: this.hasMeasurements,
      Measurements: this.Measurements
    };
  
    // Editar un producto existente
    if (this.isEditing && this.editingList !== null && this.editingIndex !== null) {
      this.editingList[this.editingIndex] = newProduct;
      this.isEditing = false;
      this.editingIndex = null;
      this.editingList = null;
      this.commonService.showSuccessMessage("Cambios Guardados");
    } else {
      // Agregar un nuevo producto a la lista correspondiente
      if (this.quantityOption === "Up 4") {
        this.up4List.push(newProduct);
      } else if (this.quantityOption === "Up 16") {
        this.up16List.push(newProduct);
      } else if (this.quantityOption === "Up 80") {
        this.up80List.push(newProduct);
      }
      this.commonService.showSuccessMessage("Producto agregado");
    }
  
    // Resetear el formulario
    this.productName = "";
    this.productId = "";
    this.productPrice = null;
    //this.hasDiscount = false;
    //this.discountedPrice = null;
    this.hasMeasurements = false;
    this.Measurements = "";
    this.quantityOption = "";
  }
  

  // Función para manejar la edición de un producto
  editProduct(list: Array<any>, index: number) {
    const product = list[index];
    this.productName = product.productName;
    this.productId = product.productId;
    this.productPrice = product.productPrice;
    //this.hasDiscount = product.hasDiscount;
    //this.discountedPrice = product.discountedPrice;
    this.hasMeasurements = product.hasMeasurements,
    this.Measurements = product.Measurements

    this.isEditing = true;
    this.editingIndex = index;
    this.editingList = list;
    
  }

  // Función para cancelar la edición
  cancelEdit() {
    this.isEditing = false;
    this.editingIndex = null;
    this.editingList = null;
  }

  // Función para eliminar un producto
  deleteProduct(list: Array<any>, index: number) {
    list.splice(index, 1);
  }

  LabelsPrint(): void {
    const ModuleName = "hds";
    this.tagsService.generateLabelsPDF(
      this.up4List,
      this.up16List,
      this.up80List,
      ModuleName
    );
  }

  SearchID() {
    if(!this.productId || this.quantityOption === null){
      this.commonService.showWarningMessage("Por favor complete los campos");
      return
    }
    this.loading = true;
    const ModuleName = "hds";
    this.tagsService.SearchID(
      this.productId,
      ModuleName,
      this.quantityOption
    ).subscribe(
      (product: Product[]) => {
        this.loading = false;
        if (this.quantityOption === 'Up 4') {
          this.up4List.push(...product) ;
        } else if (this.quantityOption === 'Up 16') {
          this.up16List.push(...product);
        } else if (this.quantityOption === 'Up 80') {
          this.up80List.push(...product);
        }
        this.commonService.showSuccessMessage("Producto agregado")


        this.productId = "";
        this.quantityOption = "";
        this.cd.detectChanges();
      },
      error => {
        this.loading = false;
        if (error.status === 404) {
          this.snackBar.open('ID no encontrado', 'Cerrar', {
            duration: 3000,
            verticalPosition: 'top'
          });
        } else {
          this.snackBar.open('ID no encontrado', 'Cerrar', {
            duration: 3000,
            verticalPosition: 'top'
          });
        }
      }
    );
  }
}
