import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth-guard.service';
import { TagsComponent } from './tags.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TagsAshleyComponent } from './tags-ashley/tags-ashley.component';
import { TagHdsComponent } from './tag-hds/tag-hds.component';
import { TagSleepComponent } from './tag-sleep/tag-sleep.component';
import { TagOutletComponent } from './tag-outlet/tag-outlet.component';
import { TagsService } from './tags.service';

export const tagsRoutes: Routes = [
  {path:"tags", canActivate: [AuthGuard], component: TagsComponent, children:[
    {path: "Ashley", canActivate: [AuthGuard], component: TagsAshleyComponent},
    {path: "hds", canActivate: [AuthGuard], component: TagHdsComponent},
    {path: "outlet", canActivate: [AuthGuard], component: TagOutletComponent},
    {path: "sleep", canActivate:[AuthGuard], component: TagSleepComponent}
  ]}
];

@NgModule({
  declarations: [
    TagsAshleyComponent,
    TagHdsComponent,
    TagSleepComponent,
    TagOutletComponent
  ],
  imports: [
    RouterModule.forChild(tagsRoutes),
    CommonModule,
    SharedModule
  ],
  exports: [RouterModule],
  providers: [
    TagsService
  ]
})
export class TagsModule { }
